<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">
<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:12.5pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:12.5pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 6.35mm'></td>
      <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 264.85mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:27.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:27.0pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:27.0pt'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:27.0pt;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>Snapshot Report - Data Details<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:2.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.0pt'></td>
      <td width=1025 colspan=2 style='width:768.85pt;padding:0in 0in 0in 0in;
      height:2.0pt;min-width: 271.20mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:30.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:30.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateformat(dateTo)}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:13.65pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:13.65pt'></td>
        <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:13.65pt;
        min-width: 6.35mm'></td>
        <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;
        height:13.65pt;min-width: 264.85mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
        min-width: 6.35mm'></td>
        <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
        min-width: 264.85mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=956 style='width:717.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 252.94mm' cols=12>
         <tr>
          <td></td>
          <td width=12 style='width:9.0pt;padding:0in 0in 0in 0in;min-width: 3.18mm'></td>
          <td width=32 style='width:24.0pt;padding:0in 0in 0in 0in;min-width: 8.47mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=56 style='width:42.0pt;padding:0in 0in 0in 0in;min-width: 14.82mm'></td>
          <td width=51 style='width:38.25pt;padding:0in 0in 0in 0in;min-width: 13.49mm'></td>
          <td width=72 style='width:.75in;padding:0in 0in 0in 0in;min-width: 19.05mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=299 style='width:224.3pt;padding:0in 0in 0in 0in;
          min-width: 79.11mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:42.3pt'>
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 colspan=2 valign=top style='width:33.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>#<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Carried /
          Received / Closed<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>On Time / Late
          / Re-Release<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Id<o:p></o:p></span></i></b></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Type<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Push Due Date<o:p></o:p></span></i></b></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Complete
          Received<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Due<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date of
          Closure<o:p></o:p></span></i></b></p>
          </td>
          <td width=299 valign=top style='width:224.3pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Summary<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:22.8pt'>
          <td width=956 colspan=12 valign=top style='width:717.1pt;border:solid black 1.0pt;
          border-top:none;background:lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:22.8pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access to Information Requests Data<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tbody v-for="(i,ReqType) in A_P" :key="i">
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=12 valign=top style='width:9.0pt;border-top:none;
          border-left:solid black 1.0pt;border-bottom:solid black 1.0pt;
          border-right:none;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=944 colspan=11 valign=top style='width:708.1pt;border-top:
          none;border-left:none;border-bottom:solid black 1.0pt;border-right:
          solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{ReqType}}({{ReqType.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <section style="display: contents;" v-for="(e,late_flag) in i" :key="e">
         <tr style='mso-yfti-irow:4;height:18.25pt'>
          <td width=12 valign=top style='width:9.0pt;border:none;border-left:
          solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;background:papayawhip;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=932 colspan=10 valign=top style='width:699.1pt;border:none;
          border-right:solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'><p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>{{late_flag}}({{e.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:15.3pt' v-for="(j) in e" :key="j">
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:solid black 1.0pt;border-right:none;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=32 valign=top style='width:24.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.indexOf(j)+1}}<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.ReqType}}<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.late_flag}}<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.reqCode}}<o:p></o:p></span></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.request_type}}<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.PushDueDate}}<o:p></o:p></span></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.dateinfocomplete}}<o:p></o:p></span></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.datedue}}<o:p></o:p></span></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.dateofclosure}}<o:p></o:p></span></p>
          </td>
          <td width=299 valign=top style='width:224.3pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{j.summary}}<o:p></o:p></span></p>
          </td>
         </tr>
         </section>
         </tbody>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=7>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:4.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:4.0pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 6.35mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=196 style='width:147.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 51.86mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=304 style='width:228.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 80.43mm'></td>
      <td width=141 style='width:105.75pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 37.31mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:22.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:22.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 6.35mm'></td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
        mso-fareast-font-family:"Times New Roman";color:black'>Weekly ATIP
        Request Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=196 valign=top style='width:147.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 51.86mm'></td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=304 valign=top style='width:228.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 80.43mm'></td>
      <td width=141 valign=top style='width:105.75pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 37.31mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>1 of 3<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>

</span></div>

</div>

<div>

<div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
 cols=3>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:12.5pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:12.5pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 6.35mm'></td>
      <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 264.85mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:27.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:27.0pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:27.0pt'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:27.0pt;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>Snapshot Report - Data Details<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:2.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.0pt'></td>
      <td width=1025 colspan=2 style='width:768.85pt;padding:0in 0in 0in 0in;
      height:2.0pt;min-width: 271.20mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:30.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:30.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateformat(dateTo)}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:12.4pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:12.4pt'></td>
        <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:12.4pt;
        min-width: 6.35mm'></td>
        <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;
        height:12.4pt;min-width: 264.85mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
        min-width: 6.35mm'></td>
        <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
        min-width: 264.85mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=956 style='width:717.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 252.94mm' cols=12>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=22 style='width:16.5pt;padding:0in 0in 0in 0in;min-width: 5.82mm'></td>
          <td width=22 style='width:16.5pt;padding:0in 0in 0in 0in;min-width: 5.82mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=56 style='width:42.0pt;padding:0in 0in 0in 0in;min-width: 14.82mm'></td>
          <td width=51 style='width:38.25pt;padding:0in 0in 0in 0in;min-width: 13.49mm'></td>
          <td width=72 style='width:.75in;padding:0in 0in 0in 0in;min-width: 19.05mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=299 style='width:224.3pt;padding:0in 0in 0in 0in;
          min-width: 79.11mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:42.3pt'>
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 colspan=2 valign=top style='width:33.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>#<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Carried /
          Received / Closed<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>On Time / Late
          / Re-Release<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Id<o:p></o:p></span></i></b></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Type<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Push Due Date<o:p></o:p></span></i></b></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Complete
          Received<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Due<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date of
          Closure<o:p></o:p></span></i></b></p>
          </td>
          <td width=299 valign=top style='width:224.3pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Summary<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:2;height:14.65pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:14.65pt'></td>
        <td width=1025 colspan=2 style='width:768.85pt;padding:0in 0in 0in 0in;
        height:14.65pt;min-width: 271.20mm'></td>
       </tr>
       <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
        min-width: 6.35mm'></td>
        <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
        min-width: 264.85mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=956 style='width:717.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 252.94mm' cols=12>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=12 style='width:9.0pt;padding:0in 0in 0in 0in;min-width: 3.18mm'></td>
          <td width=32 style='width:24.0pt;padding:0in 0in 0in 0in;min-width: 8.47mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=56 style='width:42.0pt;padding:0in 0in 0in 0in;min-width: 14.82mm'></td>
          <td width=51 style='width:38.25pt;padding:0in 0in 0in 0in;min-width: 13.49mm'></td>
          <td width=72 style='width:.75in;padding:0in 0in 0in 0in;min-width: 19.05mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=299 style='width:224.3pt;padding:0in 0in 0in 0in;
          min-width: 79.11mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:42.3pt'>
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 colspan=2 valign=top style='width:33.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>#<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Carried /
          Received / Closed<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>On Time / Late
          / Re-Release<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Id<o:p></o:p></span></i></b></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Type<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Push Due Date<o:p></o:p></span></i></b></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Complete
          Received<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Due<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date of
          Closure<o:p></o:p></span></i></b></p>
          </td>
          <td width=299 valign=top style='width:224.3pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Summary<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:22.8pt'>
          <td width=956 colspan=12 valign=top style='width:717.1pt;border:solid black 1.0pt;
          border-top:none;background:lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:22.8pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Consultation Requests Data<o:p></o:p></span></i></b></p>
          </td>
         </tr>
          <tbody v-for="(i,ReqType) in AC" :key="i">
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=12 valign=top style='width:9.0pt;border-top:none;
          border-left:solid black 1.0pt;border-bottom:solid black 1.0pt;
          border-right:none;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=944 colspan=11 valign=top style='width:708.1pt;border-top:
          none;border-left:none;border-bottom:solid black 1.0pt;border-right:
          solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{ReqType}}({{ReqType.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <section style="display: contents;"  v-for="(e,cab_docs) in i" :key="e">
         <tr style='mso-yfti-irow:4;height:18.25pt'>
          <td width=12 valign=top style='width:9.0pt;border:none;border-left:
          solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;background:papayawhip;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=932 colspan=10 valign=top style='width:699.1pt;border:none;
          border-right:solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>{{cab_docs}}({{e.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:15.3pt' v-for="(j) in e" :key="j">
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:solid black 1.0pt;border-right:none;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=32 valign=top style='width:24.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.indexOf(j)+1}}<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.ReqType}}<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.late_flag}}<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.reqCode}}<o:p></o:p></span></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.request_type}}<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.pushduedate}}<o:p></o:p></span></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.dateinfocomplete}}<o:p></o:p></span></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.datedue}}<o:p></o:p></span></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.dateofclosure}}<o:p></o:p></span></p>
          </td>
          <td width=299 valign=top style='width:224.3pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{j.summary}}<o:p></o:p></span></p>
          </td>
         </tr>
         </section>
         </tbody>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=7>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:4.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:4.0pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 6.35mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=196 style='width:147.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 51.86mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=304 style='width:228.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 80.43mm'></td>
      <td width=141 style='width:105.75pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 37.31mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:22.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:22.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 6.35mm'></td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
        mso-fareast-font-family:"Times New Roman";color:black'>Weekly ATIP
        Request Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=196 valign=top style='width:147.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 51.86mm'></td>
      <td valign=top style='padding:0in 0in 0in 0in;height:22.5pt;width:mm;
      min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=304 valign=top style='width:228.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 80.43mm'></td>
      <td width=141 valign=top style='width:105.75pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 37.31mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>2 of 3<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

</div>

</div>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>

</span></div>

</div>

<div>

<div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
 cols=3>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:12.5pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:12.5pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 6.35mm'></td>
      <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;height:12.5pt;
      min-width: 264.85mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:27.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:27.0pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:27.0pt'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:27.0pt;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>Snapshot Report - Data Details<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:2.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.0pt'></td>
      <td width=1025 colspan=2 style='width:768.85pt;padding:0in 0in 0in 0in;
      height:2.0pt;min-width: 271.20mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:30.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:30.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 6.35mm'></td>
      <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
      height:30.5pt;min-width: 264.85mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=956 valign=top style='width:717.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 252.94mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateformat(dateTo)}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:15.4pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:15.4pt'></td>
        <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:15.4pt;
        min-width: 6.35mm'></td>
        <td width=1001 style='width:750.85pt;padding:0in 0in 0in 0in;
        height:15.4pt;min-width: 264.85mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
        min-width: 6.35mm'></td>
        <td width=1001 valign=top style='width:750.85pt;padding:0in 0in 0in 0in;
        min-width: 264.85mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=956 style='width:717.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 252.94mm' cols=9>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=12 style='width:9.0pt;padding:0in 0in 0in 0in;min-width: 3.18mm'></td>
          <td width=32 style='width:24.0pt;padding:0in 0in 0in 0in;min-width: 8.47mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=56 style='width:42.0pt;padding:0in 0in 0in 0in;min-width: 14.82mm'></td>
          <td width=72 style='width:.75in;padding:0in 0in 0in 0in;min-width: 19.05mm'></td>
          <td width=70 style='width:52.5pt;padding:0in 0in 0in 0in;min-width: 18.52mm'></td>
          <td width=516 style='width:387.05pt;padding:0in 0in 0in 0in;
          min-width: 136.53mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:42.3pt'>
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 colspan=2 valign=top style='width:33.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>#<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Access/Privacy<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Id<o:p></o:p></span></i></b></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Request Type<o:p></o:p></span></i></b></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date Received<o:p></o:p></span></i></b></p>
          </td>
          <td width=70 valign=top style='width:52.5pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Date of
          Closure<o:p></o:p></span></i></b></p>
          </td>
          <td width=516 valign=top style='width:387.05pt;border:solid black 1.0pt;
          border-left:none;mso-border-left-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:42.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complaint Text
          / Summary<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:22.8pt'>
          <td width=956 colspan=9 valign=top style='width:717.1pt;border:solid black 1.0pt;
          border-top:none;background:lightsteelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:22.8pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Complaint Investigations Data<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tbody v-for="(i,ReqType) in complaints" :key="i">
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=12 valign=top style='width:9.0pt;border-top:none;
          border-left:solid black 1.0pt;border-bottom:solid black 1.0pt;
          border-right:none;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=944 colspan=8 valign=top style='width:708.1pt;border-top:
          none;border-left:none;border-bottom:solid black 1.0pt;border-right:
          solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{ReqType}}({{ReqType.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <section style="display: contents;" v-for="(e,req_type) in i" :key="e">
         <tr style='mso-yfti-irow:4;height:18.25pt'>
          <td width=12 valign=top style='width:9.0pt;border:none;border-left:
          solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;background:papayawhip;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=932 colspan=7 valign=top style='width:699.1pt;border:none;
          border-right:solid black 1.0pt;background:papayawhip;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:18.25pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>{{req_type}}({{e.length}})<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:15.3pt'  v-for="(j) in e" :key="j">
          <td width=12 valign=top style='width:9.0pt;border:solid black 1.0pt;
          border-right:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=12 valign=top style='width:9.0pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:solid black 1.0pt;border-right:none;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=32 valign=top style='width:24.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.indexOf(j)+1}}<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.ReqType}}<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.reqCode}}<o:p></o:p></span></p>
          </td>
          <td width=56 valign=top style='width:42.0pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.request_type}}<o:p></o:p></span></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.datereceived}}<o:p></o:p></span></p>
          </td>
          <td width=72 valign=top style='width:.75in;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Calibri",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{j.dateclosed}}<o:p></o:p></span></p>
          </td>
          <td width=516 valign=top style='width:387.05pt;border:solid black 1.0pt;
          border-left:none;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.3pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{j.desc}}<o:p></o:p></span></p>
          </td>
         </tr>
         </section>
         </tbody>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1025 style='width:768.85pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=7>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:4.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:4.0pt'></td>
      <td width=24 style='width:.25in;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 6.35mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=196 style='width:147.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 51.86mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 47.63mm'></td>
      <td width=304 style='width:228.0pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 80.43mm'></td>
      <td width=141 style='width:105.75pt;padding:0in 0in 0in 0in;height:4.0pt;
      min-width: 37.31mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:22.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:22.5pt'></td>
      <td width=24 valign=top style='width:.25in;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 6.35mm'></td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
        mso-fareast-font-family:"Times New Roman";color:black'>Weekly ATIP
        Request Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=196 valign=top style='width:147.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 51.86mm'></td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=304 valign=top style='width:228.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 80.43mm'></td>
      <td width=141 valign=top style='width:105.75pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 37.31mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>3 of 3<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>
<p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'><o:p>&nbsp;</o:p></span></p>

</div>

</div>


        
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
 import moment from 'moment';
//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
       data() {
    return {
          A_P:[],
          AC:[],
          complaints:[]
    };
  },
  methods: {
     Month(date) {
            return moment(date).format('MMM');
     },
     Day(date) {
            return moment(date).format('DD');
     },
     Year(date) {
            return moment(date).format('yyyy');
    },
    dateformat(date){
        return this.Month(date)+" "+this.Day(date)+","+this.Year(date);
    },
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId
      }
      const response = axcessApi.post("/report/snapshot",data);
      response.then(output => {
        this.A_P=output.data._A_P;
        const groupByType = this.groupBy("ReqType");
        this.A_P=groupByType(this.A_P);
        const groupBylate_flag = this.groupBy("late_flag");
        Object.keys(this.A_P).forEach(key => {
        this.A_P[key]=groupBylate_flag(this.A_P[key]);
        });
         
        this.AC=output.data._AC;
        this.AC=groupByType(this.AC);
        const groupBycab_docs = this.groupBy("cab_docs");
        Object.keys(this.AC).forEach(key => {
        this.AC[key]=groupBycab_docs(this.AC[key]);
        });

        this.complaints=output.data.complaints;
        this.complaints=groupByType(this.complaints);
        const groupByrequest_type = this.groupBy("req_type");
        Object.keys(this.complaints).forEach(key => {
        this.complaints[key]=groupByrequest_type(this.complaints[key]);
        });
         console.log("sal",this.complaints);
      })
    },
    selected() {
    },
     groupBy(key) {
    return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
}

  }
};
</script>