
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select v-model="reqType"   @change="selected" :items="reqTypes" item-text="label" item-value="id" label="Choose Snapshot Report Request"></v-select>
          </v-col>
        </v-row>


        <v-row v-if="reqType== 0">
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
        </v-row>

         <v-row v-if="reqType== 1">
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select v-model="block" :items="blocks" item-text="name" item-value="name" label="Report Block"></v-select>
          </v-col>
        </v-row>

  

        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>


      </v-card-text>
    </v-card>
    <snapshot v-if="reqType === 0 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo"></snapshot>
    <snapshotdetails v-if="reqType === 1 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo"></snapshotdetails>

    <div v-else>
      <v-progress-circular
          v-if="flag==2"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import DatePicker from "../components/DatePicker";
import snapshot from "../components/report/snapshot";
import snapshotdetails from "../components/report/snapshotdetails";

export default {
  created () {
  },
  components: {
    DatePicker,
    snapshot,
    snapshotdetails
 

  },
  data(){
    return {
      flag:0,
      reqType:"-1",
      reqTypes: [
        {
          id:0,
          label: "SnapShot Report",
        },
        {
          id:1,
          label: "SnapShot Report Details",
        }
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      blocks:[
        { id:'0',name:"All"},
         { id:'1',name:"Access To information Requests"},
          { id:'2',name:"Consultation Requests"},
           { id:'3',name:"Privacy Requests"},
            { id:'4',name:"Complaints"}

      ],
      block:"All",
      Officers:[
        { id:0,name:"All Officers"}

      ],
      Officer:0,
      Sections:[
        { id:0,name:"All Sections"}

      ],
      Section:0,
    };
  },
  methods:{
    selected(){

    },
    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    }
  }
};

</script>


<style lang="scss" scoped>

</style>
